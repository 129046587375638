<template>
	<div class="fill-height">
		<SideBarLeft />
		<SideBarRight v-if="$route.name === 'home'" :pevents="events" />
		<SideBarRightItem v-if="$route.params[$route.name]" :pevents="events" />
		<BreadCrumbs v-if="$route.name !== 'home'" />

		<router-view :pevents="events" />

		<Button
				v-if="$route.name === 'home' && drawer_ready"
				iconVal="mdi-cog"
				carre
				fixed
				:bottom="52"
				:right="drawer_widgets ? 234 : 12"
				:styles="{zIndex: 4}"
				:click="() => {drawer_widgets = !drawer_widgets; drawer = !drawer_widgets}"
				iconSmall
		/>
	</div>
</template>

<script>
	export default {
		name: "Dashboard",
		components: {
			SideBarLeft: () => import('@/layout/dashboard/SideBarLeft.vue'),
			SideBarRight: () => import('@/layout/dashboard/SideBarRight.vue'),
			SideBarRightItem: () => import('@/layout/dashboard/SideBarRightItem.vue'),
			BreadCrumbs: () => import('@/components/ui/BreadCrumbs.vue'),
			Button: () => import('@/components/ui/Button.vue')
		},
		data(){
			return {
				events: null,
				drawer_ready: false
			}
		},
		computed: {
			drawer: {
				get(){
					return this.$store.getters['dashboard/drawer']
				},
				set(val){
					this.$store.commit('dashboard/CHANGE_DRAWER_VAL', val)
				}
			},
			drawer_widgets: {
				get(){
					return this.$store.getters['dashboard/drawer_widgets']
				},
				set(val){
					this.$store.commit('dashboard/CHANGE_DRAWER_WIDGETS_VAL', val)
				}
			},
			ready(){
				return this.$store.getters.application_ready
			}
		},
		watch: {
			ready(val){
				if(!val){
					this.events.notify('update-app')
				}
			}
		},
		methods: {
			setUpHooks(){
				this.events = new this.$NVEvent('Dashboard')
				this.events.wait("base-widget", (l)=>{
					this.drawer_ready = l.length > 0
					console.log(l)
				})
			}
		},
		created() {
			this.setUpHooks()
		}
	}
</script>

<style scoped>

</style>
